import {Options, Vue} from "vue-class-component";
import {invoicesService} from "@services/invoices.service";
import {InvoiceTypeEnum} from "@/model/api/Invoice";
import {fileDownload, getFileNameFromHeader} from "@/utils/utils";
import {FilterMatchMode} from "primevue/api";
import {AppTable} from "@components";
import {Prop} from "vue-property-decorator";
import {User} from "@/model/api/User";

@Options({
    components: {
        AppTable
    }
})
export default class UserInvoices extends Vue {

    @Prop() readonly userId!: String;

    @Prop() readonly user!: User;

    filters: any = null;

    get service() {
        return invoicesService;
    }

    get typesOptions() {
        return Object.keys(InvoiceTypeEnum).map((key) => {
            return { label: this.$t(`invoices.type_${key}`), value: key }
        })
    }

    async downloadInvoices(id: number){
        this.$waitFor(
            async () => {
                const response = await invoicesService.downloadInvoices(id)
                try {
                    const fileName = getFileNameFromHeader(response.headers)
                    fileDownload(
                        fileName,
                        response.data,
                        response.headers['content-type']
                    )
                } catch (err) {
                    this.$errorMessage(this.$t('Operation failed'))
                }
            }
        );
    }

    private initFilter() {
        this.filters = {
            driver_id : {
                value: this.userId,
                matchMode: FilterMatchMode.EQUALS
            },
            type : {
                value: InvoiceTypeEnum.DRIVER,
                matchMode: FilterMatchMode.EQUALS
            },
            business_zip: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            business_country: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            business_city: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            business_address: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            business_name: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            mov_id: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            }
        };


    }

    created() {
        this.initFilter();
    }
}