import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-49964c38")
const _hoisted_1 = { class: "p-field" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, {
    class: "p-mb-3",
    header: "Filters",
    toggleable: true,
    collapsed: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FloatLabel, { label: "WRS Date" }, {
          default: _withCtx(() => [
            _createVNode(_component_Calendar, {
              modelValue: _ctx.advFilters.shift_day,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.advFilters.shift_day = $event)),
              onDateSelect: _ctx.emitFilters,
              dateFormat: "yy-mm-dd",
              showIcon: true
            }, null, 8, ["modelValue", "onDateSelect"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}