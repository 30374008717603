import { Type } from "class-transformer";
import moment from "moment";

export class WRSFilters {
    @Type(() => Date)
    shift_day: Date;

    constructor() {
        const from = moment();

        this.shift_day = from.toDate();
    }
}