import {User} from "@/model/api/User";
import {usersService} from "@services/users.service";
import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";

import {authStore} from "@/modules/auth/store";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";

import {
    ContractAutocomplete,
    ImageInput,
    StateAutocomplete,
    UserRoleSelect,
    CompanyAutocomplete, YesNo
} from "@components";

import {UserStatusEnum} from "@/model/enums/UserStatusEnum";
import {State} from "@/model/api/State";
import {fileDownload} from "@/utils/utils";
import {Contract} from "@/model/api/Contract";
import {Company} from "@/model/api/Company";
import {companiesService} from "@services/companies.service";
import {CompanyTypeEnum} from "@/model/enums/CompanyTypeEnum";
import {LiveViewRoutesEnum} from "@/modules/live-view/router";

@Options({
    components: {
        ContractAutocomplete,
        ImageInput,
        UserRoleSelect,
        StateAutocomplete,
        CompanyAutocomplete,
        YesNo
    }
})
export default class UserData extends Vue {

    @Prop() readonly userId!: String;

    @Prop() readonly user!: User;

    confirmPassword: string = null;

    selectedState: string | State = null;

    displayMessageDialog: boolean = false;

    get currentRole() {
        return authStore.getters.userRole;
    }

    async resendEmail(){
        await this.$waitFor(
            async () => {
                await usersService.sendVerificationEmail(this.user.id);
                this.$successMessage('Email sent');
            },
            this.$t('Operation failed')
        )
    }

    goToLive() {
        const routeData = this.$router.resolve({
            name: LiveViewRoutesEnum.PREFIX,
            query: {id: this.user.id}
        });
        window.open(routeData.href, '_blank');
    }

    get roleOptions() {
        const roles = [];

        const isSA = this.currentRole === UserRoleEnum.SUPER_ADMIN;

        if (isSA) {
            roles.push(...[
                {label: 'SUPER ADMIN', value: UserRoleEnum.SUPER_ADMIN},
                {label: 'DRIVER', value: UserRoleEnum.DRIVER},
            ]);
        }

        return roles;
    }

    get statusOptions() {
        return [
            {label: 'CREATED', value: UserStatusEnum.CREATED},
            {label: 'ACTIVE', value: UserStatusEnum.ACTIVE},
            {label: 'DISABLED', value: UserStatusEnum.DISABLED},
        ]
    }

    get isOperator() {
        return this.user?.role === UserRoleEnum.DRIVER;
    }

    get hasLicenseFile() {
        return this.user?.license_image_path;
    }

    get hasInsuranceFile() {
        return this.user?.insurance_image_path;
    }

    onContractSelect(contract: Contract) {
        this.user.contract_id = contract?.id;
    }

    async assignBroker() {
        if(!this.user.company_name){
            this.$errorMessage("The company name field is required.")
            return
        }
        await this.$waitFor(
            async () => {

                const company: Company = {
                    type: CompanyTypeEnum.BROKER,
                    name: `${this.user.name} ${this.user.surname}`,
                    business_name: `${this.user.company_name}`,
                    business_city: this.user.city,
                    business_zip: this.user.zip,
                    email: this.user.email,
                    phone: this.user.phone,
                }

                const broker = await companiesService.create(company);
                this.$successMessage("Broker successfully created")
                this.user.broker = broker
            },
            this.$t('Operation failed')
        )

    }

    async downloadLicenseFile() {
        if (this.user.license_image_path_url) {
            const response = await usersService
                .downloadFile(this.user.license_image_path_url);

            const fileName = this.user.license_image_path_url.split('/').pop();
            const ext = fileName.split('.').pop();

            fileDownload(
                `${fileName}`,
                response as any,
                `image/${ext}`
            )
        }
    }

    onBrokerSelected(broker?: Company) {
        if (broker) {
            this.user.broker_id = broker.id
        } else {
            this.user.broker = null
            this.user.broker_id = null
        }
    }
}