import {User} from "@/model/api/User";
import {RolesUserCanSee, UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {SessionStorageEnum} from "@/utils/SessionStorageEnum";
import {usersService} from "@services/users.service";
import {FilterMatchMode} from "primevue/api";
import {Options, Vue} from "vue-class-component";
import {UsersRoutesEnum} from "../../router";

import {AdminRoleSelect, DynamicTable, UserRole, YesNo} from "@components";
import {authStore} from "@/modules/auth/store";

@Options({
  name: 'AdminsPage',

  components: {
    DynamicTable,
    UserRole
  },

  beforeRouteEnter(to, from, next) {
    console.debug("before enter admins");
    if (from.name
      && !(from.name as string).startsWith(UsersRoutesEnum.ADMINS_PREFIX)) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_ADMINS);
    }

    next();
  },

  beforeRouteLeave() {
    if (usersService?.cancelPendingRequests) {
      usersService.cancelPendingRequests();
    }
  },
})
export default class AdminsPage extends Vue {
  filters: any = null;

  get service() {
    return usersService;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_ADMINS
  }

  get newRoute() {
    return {
      name: UsersRoutesEnum.ADMINS_DETAIL,
      params: { userId: 'new' }
    }
  }

  get cols() {
    return [
      {
        field: 'name',
        header: this.$t('admin.table.first_name'),
        placeHolder: "Name contains"
      },
      {
        field: 'surname',
        header: this.$t('admin.table.last_name'),
        placeHolder: "Surname contains"
      },
      {
        field: 'email',
        header: this.$t('admin.table.email'),
        placeHolder: "E-mail contains"
      },
      {
        field: 'is_user_free_dump_req',
        header: this.$t('admin.table.is_user_free_dump_req'),
        component: YesNo
      },
      {
        field: 'role',
        header: this.$t('admin.table.role'),
        filter_component: AdminRoleSelect,
        filter_props: {
          showAdmin: RolesUserCanSee[authStore.getters.me.role] ? RolesUserCanSee[authStore.getters.me.role].includes(UserRoleEnum.SUPER_ADMIN) : true
        },
        component: UserRole
      },
      {
        field: 'broker.business_name',
        header: this.$t('admin.table.broker'),
      },
      {
        field: 'client.business_name',
        header: this.$t('admin.table.client'),
      },
      {
        field: 'customer.business_name',
        header: this.$t('admin.table.customer'),
      },
    ]
  }

  goToDetail(user: User) {
    this.$router.push({
      name: UsersRoutesEnum.ADMINS_DETAIL,
      params: { userId: user.id }
    })
  }

  onDelete(user: User) {
    this.$confirmMessage("Are you sure to delete this User?")
      .then(r => {
        if (r) {
          this.deleteUser(user);
        }
      })
  }

  private deleteUser(user: User) {
    this.$waitFor(
      async () => {
        await usersService.deleteBySystem(user);
        this.$successMessage("User successfully deleted")
      },
      "Deleting User failed"
    )
  }  

  private initFilter() {
    this.filters = {
      global: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH,
      },
      surname: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH,
      },
      email: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      role: {
        value: RolesUserCanSee[authStore.getters.me.role] || [
          UserRoleEnum.SUPER_ADMIN,
          UserRoleEnum.BROKER,
          UserRoleEnum.CUSTOMER,
          UserRoleEnum.FOREMAN
        ],
        matchMode: FilterMatchMode.IN,
      },
      'broker.business_name': {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      'client.business_name': {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      'customer.business_name': {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
    };
  }

  created() {
    this.initFilter();
  }
}
