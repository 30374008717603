import { User } from "@/model/api/User";
import { usersService } from "@services/users.service";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { UsersRoutesEnum } from "../../router";
import { authStore } from "@/modules/auth/store";

import {
  CompanyAutocomplete,
  ImageInput,
  PageHeader,
  StateAutocomplete
} from "@components";

import { UserStatusEnum } from "@/model/enums/UserStatusEnum";
import {RolesUserCanSee, UserRoleEnum} from "@/model/enums/UserRoleEnum";
import { Company } from "@/model/api/Company";
import {CompanyTypeEnum} from "@/model/enums/CompanyTypeEnum";

@Options({
  components: {
    ImageInput,
    PageHeader,
    CompanyAutocomplete,
    StateAutocomplete
  }
})
export default class AdminPage extends Vue {
  @Prop() readonly userId!: string;

  isLoading: boolean = false;

  user: User = null;
  confirmPassword: string = null;

  get currentRole() {
    return authStore.getters.userRole;
  }

  get rolesOptions() {
    return RolesUserCanSee[authStore.getters.me.role]?.map((val) => {
      return {
        code: val,
        name: this.$t(`role.${val}`)
      }
    }) || [
      { name: 'Admin', code: UserRoleEnum.SUPER_ADMIN },
      { name: 'Customer', code: UserRoleEnum.CUSTOMER },
      { name: 'Broker', code: UserRoleEnum.BROKER },
      { name: 'Foreman', code: UserRoleEnum.FOREMAN },
      { name: 'Client', code: UserRoleEnum.CLIENT },
      { name: 'Project Manager', code: UserRoleEnum.PM },
      { name: 'Account', code: UserRoleEnum.ACCOUNT },
    ]
  }

  get statusOptions() {
    return [
      { label: 'ACTIVE', value: UserStatusEnum.ACTIVE },
      { label: 'DISABLED', value: UserStatusEnum.DISABLED },
    ]
  }

  changeRole($event) {
    if ($event.value === UserRoleEnum.BROKER) {
      this.user.customer = null
      this.user.customer_id = null
    }
  }

  get isNew() {
    return this.userId === 'new';
  }

  get backRoute() {
    return { name: UsersRoutesEnum.ADMINS_LIST };
  }

  get CompanyType(){
    return CompanyTypeEnum
  }

  get UserRole(){
    return UserRoleEnum
  }

  async loadUser() {
    this.$waitFor(
      async () => {
        this.user = await usersService.getById(+this.userId)
      }
    );
  }

  private deleteUser() {
    const message = this.$t(
      'user.delete_message',
      { name: `${this.user.name} ${this.user.surname}` }
    );

    const header = this.$t('message.confirm');

    this.$confirmMessage(message, header)
      .then((r) => {
        if (r) {

          this.$waitFor(async () => {
            await usersService.deleteBySystem(this.user);

            this.$router
              .replace(this.backRoute)
              .then(() => this.$successMessage("User successfully deleted"));
          });
        }
      });
  }

  async onSave(): Promise<any> {
    this.user.email = this.user.email?.replace(/ /g, '')
    this.user.status = UserStatusEnum.ACTIVE;

    this.$waitFor(
      async () => {

        if (this.isNew) {
          const response = await usersService.create(this.user);

          this.user = { ...this.user, ...response };
          
          this.$successMessage("User successfully created");          
        } else {
          await usersService.updatePatch(this.user);
          this.$successMessage("User successfully updated");
        }

        this.$router.back();
      },

      "Saving failed"
    )
  }

  onDelete() {
    this.deleteUser();
  }

  async mounted() {
    if (!this.isNew) {
      await this.loadUser();
    } else {
      this.user = new User();
    }
  }

  onCustomerSelect(customer: Company) {
    if (customer) {
      this.user.customer_id = customer.id;
    } else {
      this.user.customer = null;
      this.user.customer_id = null;
    }
  }

  onCompanySelect(company: Company, companyKey: string) {
    if (company) {
      this.user[companyKey + '_id'] = company.id;
    } else {
      this.user[companyKey] = null;
      this.user[companyKey + '_id'] = null;
    }
  }
}
