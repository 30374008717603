import { CRUD } from './base/crud';
import {Shift} from "@/model/api/Shift";
import {User} from "@/model/api/User";
import { plainToClass } from 'class-transformer';
import { PaginatedResponse } from '@/model/api/PaginatedResponse';
class WRSService extends CRUD<Shift, any> {
    readonly endPoint = `shifts/by-broker`;

    public async getShiftsByBroker(params: any): Promise<Shift[]> {
        let res = await this.get<Shift[]>(`${this.endPoint}`, {params})
        res = plainToClass(Shift, res);
        res.forEach((shift: Shift) => {
            (shift as any).original_dates = {
                start_datetime: shift.start_datetime,
                end_datetime: shift.end_datetime,
                expire_at: shift.expire_at
            }
            shift.updateDates()
        })
        return res;

    }

    public updateEnableSelfAccept(driverId: number, enable_self_accept: boolean): Promise<User[]> {
        return this.post<User[]>(`users/${driverId}/updateEnableSelfAccept`, {
            enable_self_accept
        })
    }

    public getBrokerDrivers(params?: any): Promise<User[]> {
        return this.get<User[]>(`users/broker-drivers`, {params})
    }

    public setStatusDriver(payload: any): Promise<any> {
        return this.post<any>(`/tickets/set-status-driver`, payload)
    }

    public deleteShift(shiftId: number): Promise<any> {
        return this.delete<any>(`${this.endPoint}/${shiftId}`)
    }

}

export const wrsService = new WRSService();



