import { Options, Vue } from "vue-class-component";
import { SessionStorageEnum } from "@/utils/SessionStorageEnum";
import { UsersRoutesEnum } from "../../router";
import { User } from "@/model/api/User";
import { FilterMatchMode } from "primevue/api";
import { wrsService } from "@services/wrs.service";
import {AppTable, CompanyAutocomplete, DeductDialog, TicketStatus} from "@components";
import WRSsFilters from "./WRSsFilters.vue";
import { WRSFilters } from "./WRSFilters";
import { Shift } from "@/model/api/Shift";
import { TicketStatusEnum } from "@/model/enums/TicketStatusEnum";
import { Ticket } from "@/model/api/Ticket";
import { ticketsService } from "@services/tickets.service";
import { ShiftsRoutesEnum } from "@/modules/shifts/router";
import { TicketsRoutesEnum } from "@/modules/tickets/router";
import {authStore} from "@/modules/auth/store";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {Company} from "@/model/api/Company";
import {PaymentStatusEnum} from "@/model/enums/PaymentStatusEnum";

enum DriverTicketStatus {
    ACCEPTED,
    ACCEPTABLE,
    UNACCEPTABLE,
}

@Options({
    name: "WrsPage",

    components: {
        AppTable,
        WRSsFilters,
        CompanyAutocomplete,
        DeductDialog,
        TicketStatus
    },

    beforeRouteEnter(to, from, next) {
        if (from.name && !(from.name as string).startsWith(UsersRoutesEnum.WRS_PREFIX)) {
            sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_WRS);
        }

        next();
    },
    beforeRouteLeave() {

        if (wrsService?.cancelPendingRequests) {

            wrsService.cancelPendingRequests();
        }
    },
})
export default class WRSList extends Vue {
    displayDeductDialog: boolean = false;
    filters: any = null;
    dataset: Shift[] = null;
    brokerDrivers: User[] = null;
    brokerSelected: Company = null;
    selectedDriver: any = null;

    get service() {
        return wrsService;
    }

    get stateKey() {
        return SessionStorageEnum.TABLE_STATE_WRS
    }

    get shiftRoute() {
        return ShiftsRoutesEnum.SHIFTS_DETAIL;
    }

    get ticketRoute() {
        return TicketsRoutesEnum.TICKETS_DETAIL;
    }

    get imAdmin() {
        return authStore.getters.me.role === UserRoleEnum.SUPER_ADMIN || authStore.getters.me.role === UserRoleEnum.CLIENT || authStore.getters.me.role === UserRoleEnum.CUSTOMER
    }

    advFilters: WRSFilters = new WRSFilters();

    onReset() {
        this.advFilters = new WRSFilters();
    }

    async updateEnableSelfAccept(driver){
        driver.isLoading = true
        await wrsService.updateEnableSelfAccept(driver.id, driver.enable_self_accept);
        driver.isLoading = false
    }

    onBrokerSelected(){
        sessionStorage.setItem(
            'WRS_broker',
            JSON.stringify(this.brokerSelected)
        );
        this.initData()
    }

    openModal(currentDriver: any){
        this.selectedDriver = currentDriver
        this.displayDeductDialog = true
    }

    updateTicket($event){
        this.selectedDriver.broker_deduct = $event.broker_deduct
        this.selectedDriver.broker_deduct_note = $event.broker_deduct_note
    }

    formatDate() {
        return `${this.advFilters.shift_day.getFullYear()}-${this.advFilters.shift_day.getMonth() + 1}-${this.advFilters.shift_day.getDate()}`
    }

    onFiltersChange() {
        this.filters.shift_day.value = this.formatDate()
        this.loadData();
    }

    private initFilter() {
        this.filters = {
            shift_day: {
                value: this.formatDate(),
                matchMode: FilterMatchMode.EQUALS
            }
        }
    }

    async acceptTicket(shift_id, driver_id) {
        this.requestPending = true
        try {
            await wrsService.setStatusDriver({
                shift_id,
                driver_id,
                broker_id: this.brokerSelected ? this.brokerSelected.id : null,
                status: 20
            });
            await this.loadData();

            this.$successMessage('Ticket accpted successfully')
        } catch (error) {
            this.$errorMessage(error.data.message)
        } finally {
            this.requestPending = false
        }

    }

    deleteTicket(ticketId: number, driverId: number) {
        this.$confirmMessage("Are you sure to delete this Ticket?")
            .then(r => {
                if (r) {
                    this.$waitFor(
                        async () => {
                            await ticketsService.deleteStandard({id: ticketId})
                            await this.loadData();

                            this.$successMessage('Ticket deleted successfully')
                        })
                }
            })
    }

    get DriverTicketStatus() {
        return DriverTicketStatus
    }

    get PaymentStatusEnum(){
        return PaymentStatusEnum
    }

    loadData() {
        this.filters.shift_day.value = this.formatDate()
        return this.$waitFor(
            async () => {
                const dataset = await wrsService.getShiftsByBroker({
                    filters: this.filters,
                    broker_id: this.brokerSelected ? this.brokerSelected.id : null
                });
                this.dataset = dataset.map((shift: any) => {
                    shift.drivers = this.brokerDrivers.map((driver: any) => {
                        const payload:any = {
                            id: driver.id
                        }
                        const currentTicket = shift.tickets.find((ticket) => ticket.user_id === driver.id)
                        if(currentTicket){
                            payload.ticketId = currentTicket.id;
                            payload.ticketStatus = currentTicket.status;
                            payload.broker_deduct = currentTicket.broker_deduct;
                            payload.broker_deduct_note = currentTicket.broker_deduct_note;
                            payload.ticket_payment_status = currentTicket.payment_status;
                            if(currentTicket.status === TicketStatusEnum.DELETED_BY_USER ||
                                currentTicket.status === TicketStatusEnum.ACCEPTED_BY_USER ||
                                currentTicket.status === TicketStatusEnum.DELETED_BY_SYSTEM)
                            {
                                payload.status = DriverTicketStatus.ACCEPTED
                            } else {
                                payload.status = DriverTicketStatus.UNACCEPTABLE

                            }
                        } else {
                            payload.status = DriverTicketStatus.ACCEPTABLE
                        }
                        return payload
                    })
                    return shift
                })
            }
        )
    }

    async initData(){
        this.initFilter();

        await this.$waitFor(async () => {
                this.brokerDrivers = await wrsService.getBrokerDrivers({
                    broker_id: this.brokerSelected ? this.brokerSelected.id : null
                });
            }
        )

        this.loadData();
    }

    async mounted() {
        if(this.imAdmin){
            if(!sessionStorage.getItem('WRS_broker')){
                return
            }
            this.brokerSelected = JSON.parse(sessionStorage.getItem('WRS_broker'))
        }
        await this.initData()
    }

}
