import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "card page--limited" }
const _hoisted_2 = {
  key: 1,
  class: "p-formgroup p-grid p-fluid"
}
const _hoisted_3 = { class: "p-field p-col-12" }
const _hoisted_4 = { class: "p-field p-col-12" }
const _hoisted_5 = { class: "p-field p-col-12" }
const _hoisted_6 = { class: "p-field p-col-12" }
const _hoisted_7 = { class: "p-col-4 p-field" }
const _hoisted_8 = {
  key: 0,
  class: "p-col-4 p-field"
}
const _hoisted_9 = {
  key: 1,
  class: "p-col-4 p-field"
}
const _hoisted_10 = {
  key: 2,
  class: "p-col-4 p-field"
}
const _hoisted_11 = { class: "p-col-4 p-field" }
const _hoisted_12 = {
  key: 3,
  class: "p-field-checkbox p-col-12"
}
const _hoisted_13 = { for: "is_user_free_dump_req" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_CompanyAutocomplete = _resolveComponent("CompanyAutocomplete")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageHeader, {
      title: _ctx.isNew ? _ctx.$t('admin.title_new') : _ctx.$t('admin.title_edit'),
      backRoute: _ctx.backRoute,
      onOnSave: _ctx.onSave,
      onOnDelete: _ctx.onDelete,
      deleteDisabled: _ctx.isNew,
      saveDisabled: _ctx.isLoading
    }, null, 8, ["title", "backRoute", "onOnSave", "onOnDelete", "deleteDisabled", "saveDisabled"]),
    (_ctx.requestPending && !_ctx.user)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_Skeleton, {
            height: "2rem",
            class: "p-mb-2"
          }),
          _createVNode(_component_Skeleton, {
            height: "2rem",
            class: "p-mb-2"
          }),
          _createVNode(_component_Skeleton, {
            height: "2rem",
            class: "p-mb-2"
          }),
          _createVNode(_component_Skeleton, {
            height: "2rem",
            class: "p-mb-2"
          }),
          _createVNode(_component_Skeleton, {
            height: "2rem",
            class: "p-mb-2"
          })
        ], 64))
      : (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_FloatLabel, { label: "First Name" }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.user.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.user.name = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_FloatLabel, { label: "Last Name" }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.user.surname,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.user.surname = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_FloatLabel, { label: "E-mail" }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.user.email,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.user.email = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_FloatLabel, { label: "Password" }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputText, {
                    autocomplete: "new-password",
                    modelValue: _ctx.user.password,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.user.password = $event)),
                    type: "password"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_FloatLabel, { label: "Role" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Dropdown, {
                    modelValue: _ctx.user.role,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.user.role = $event)),
                    options: _ctx.rolesOptions,
                    onChange: _ctx.changeRole,
                    optionLabel: "name",
                    optionValue: "code",
                    placeholder: "Select a Role"
                  }, null, 8, ["modelValue", "options", "onChange"])
                ]),
                _: 1
              })
            ]),
            (_ctx.user.role === _ctx.UserRole.CUSTOMER ||
                 _ctx.user.role === _ctx.UserRole.FOREMAN ||
                 _ctx.user.role === _ctx.UserRole.PM ||
                 _ctx.user.role === _ctx.UserRole.ACCOUNT)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_CompanyAutocomplete, {
                    modelValue: _ctx.user.customer,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.user.customer = $event)),
                    type: 300,
                    label: "Customer",
                    onSelect: _ctx.onCustomerSelect
                  }, null, 8, ["modelValue", "onSelect"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.user.role === _ctx.UserRole.CUSTOMER || _ctx.user.role === _ctx.UserRole.BROKER)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_CompanyAutocomplete, {
                    modelValue: _ctx.user.broker,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.user.broker = $event)),
                    type: _ctx.CompanyType.BROKER,
                    label: _ctx.$t('admin.broker'),
                    onSelect: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onCompanySelect($event, 'broker')))
                  }, null, 8, ["modelValue", "type", "label"])
                ]))
              : (_ctx.user.role === _ctx.UserRole.CLIENT)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_CompanyAutocomplete, {
                      modelValue: _ctx.user.client,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.user.client = $event)),
                      type: _ctx.CompanyType.CLIENT,
                      label: _ctx.$t('admin.client'),
                      onSelect: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onCompanySelect($event, 'client')))
                    }, null, 8, ["modelValue", "type", "label"])
                  ]))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_FloatLabel, { label: "Status" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Dropdown, {
                    modelValue: _ctx.user.status,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.user.status = $event)),
                    options: _ctx.statusOptions,
                    optionLabel: "label",
                    optionValue: "value",
                    placeholder: "Select a Status"
                  }, null, 8, ["modelValue", "options"])
                ]),
                _: 1
              })
            ]),
            (_ctx.user.role === _ctx.UserRole.SUPER_ADMIN || _ctx.user.role === _ctx.UserRole.CUSTOMER)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(_component_Checkbox, {
                    id: "is_user_free_dump_req",
                    modelValue: _ctx.user.is_user_free_dump_req,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.user.is_user_free_dump_req = $event)),
                    binary: true
                  }, null, 8, ["modelValue"]),
                  _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('admin.is_user_free_dump_req')), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
  ]))
}