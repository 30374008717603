
  import { SessionStorageEnum } from "@/utils/SessionStorageEnum";
  import { plainToClass } from "class-transformer";
  import { Options, Vue } from "vue-class-component";
  import { Model } from "vue-property-decorator";
  import {WRSFilters} from "./WRSFilters";

  @Options({})
  export default class WRSsFilters extends Vue {
    @Model("filters")
    advFilters: WRSFilters;

    emitFilters() {
      this.saveAdvancedFilters();
      this.$emit("change", this.advFilters);
    }

    private saveAdvancedFilters() {
      sessionStorage.setItem(
        SessionStorageEnum.WRS_ADVANCED_FILTERS,
        JSON.stringify({
          shift_day: `${this.advFilters.shift_day.getFullYear()}-${this.advFilters.shift_day.getMonth() + 1}-${this.advFilters.shift_day.getDate()}`
        })
      );
    }

    private restoreAdvancedFilters() {
      const fromStorage = JSON.parse(
        sessionStorage.getItem(SessionStorageEnum.WRS_ADVANCED_FILTERS)
      );

      this.advFilters = fromStorage
        ? plainToClass(WRSFilters, fromStorage)
        : this.advFilters;

    }

    created() {
      this.restoreAdvancedFilters();
    }
  }
